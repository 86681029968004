export default {
  data() {
    return {
      currentCounter: 1,
      swiperOption: {
        centeredSlides: true,
        navigation: {
          nextEl: '.team-controls__button--next',
          prevEl: '.team-controls__button--prev'
        }
      }
    }
  },
  computed: {
    teamLength() {
      return this.$store.state.team.members.length
    },
    swiper() {
      return this.$refs.swiper.swiper
    }
  },
  methods: {
    handleSlideChange() {
      this.updateCarouselCounter()
      // this.updateCarouselTeamName()
    },
    updateCarouselCounter() {
      if (this.swiper.activeIndex >= this.currentCounter) {
        this.currentCounter++
      } else {
        this.currentCounter--
      }
    },
    memberClasses(index) {
      const actualIndex = this.currentCounter - 1
      return {
        'team-controls__name--active': index === actualIndex,
        'team-controls__name--top': index < actualIndex
      }
    }
  }
}
